import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const ErrorBlock = ({ children }: Props) => {
  return (
    <div
      className="bg-alertLight2 border border-alertDark1 rounded-sm text-alert text-xs text-center m-0 pt-sm pb-sm pl-md pr-md"
      role="alert"
    >
      {children}
    </div>
  );
};
